import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.3 Warranties that HostJane makes:`}</h3>
    <p><strong parentName="p">{`2.3.1`}</strong>{` HostJane warrants to You that the HostJane Marketplace will be suitable for the purposes described on the sites, and that we will provide payment and customer support services to You with reasonable skill and care.`}</p>
    <p><strong parentName="p">{`2.3.2`}</strong>{` That upon receiving successful payment for a Buyer order that HostJane will notify applicable Seller of the order by email and the Site notification system when Seller is logged in; `}</p>
    <p><strong parentName="p">{`2.3.3`}</strong>{` HostJane otherwise does not make any express or implied warranties about the quality, fitness for purpose, or reliability of any Services sold through HostJane or the Skills of the Seller offering those services. `}</p>
    <p><strong parentName="p">{`2.3.4`}</strong>{` HostJane does not monitor User Content. We do not read or vet the quality of any Listing offered through our Marketplace. `}</p>
    <p><strong parentName="p">{`2.3.5`}</strong>{` HostJane will not be liable for any claims, or incidental, consequential or other damages arising out of use of the Site, or the delivery through the Site or use of a Work Preview or Final Work.`}</p>
    <p><strong parentName="p">{`2.3.6`}</strong>{` HostJane is not a bank, fiduciary, trustee, financial service provider, licensed escrow agent, or licensed deposit taker in the U.S. or elsewhere, and Your payments to Us are not deposits under U.S. banking legislation, excluding You from the benefit of any deposit insurance or guarantee under current law. `}</p>
    <p><strong parentName="p">{`2.3.7`}</strong>{` We care about Your privacy. You can read our Privacy Policy by going to `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/privacy/"
      }}>{`https://www.hostjane.com/legal/privacy/`}</a>{`. The Privacy Policy is a part of these Terms of Service and incorporated herein by reference.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      