import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`10. Governing Law; Jurisdiction`}</h2>
    <p>{`Any controversy or claim arising out of or relating to this Agreement,
the formation of this Agreement or the breach of this Agreement, including any
claim based upon an alleged tort, shall be governed by the substantive laws of
the State of Wyoming. The United Nations Convention on Contracts for the International
Sale of Goods does not apply to this Agreement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      