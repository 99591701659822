import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`2.5`}</strong>{` Regardless of whether we may be liable for such infringement under local country law or United States law, we may respond to these notices by removing or disabling access to material claimed to infringe and/or terminating Users of Our Services. If we remove or disable access in response to such a notice, we will make a good-faith attempt to contact the owner or administrator of the affected site or content so that the owner or administrator may make a counter notification.`}</p>
    <p><strong parentName="p">{`2.6`}</strong>{` We may also document notices of alleged infringement on which we act. As with all legal notices, a copy of the notice may be made available to the public and sent to one or more third parties who may make it available to the public.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      