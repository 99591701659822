import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`4. THIRD PARTY COOKIES`}</h3>
    <p>{`In addition to the first party cookies set by Us, We also allow third parties to set cookies on Our Websites.  Third parties may use cookies, web beacons, pixels, tags, scripts and other similar tracking technologies to enable the third party’s features or functionalities to be provided through the Website You are using. The third party setting these cookies can recognize Your device both when it visits Our Website and when it visits certain other websites or services. For example, Our paid endorsers, or affiliates, may use third party cookies to identify You as a referral so they can be compensated if You sign up for services from Us as a result of visiting one of Our paid endorsers. The service providers of third-party cookies have their own privacy policies, and may use their cookies to target advertising to You.`}</p>
    <h3>{`5. CROSS-DEVICE TRACKING`}</h3>
    <p>{`We perform cross-device tracking which allows Us to provide more relevant advertising to You on multiple devices. We do this by identifying browsing activity on Your smartphones, tablets, desktop computers and other devices connected to the same IP address or logged into the same account to better understand the products and Services that might be of interest to You.`}</p>
    <h3>{`6. LOCAL STORAGE OBJECTS`}</h3>
    <p>{`We also use Local Storage Objects (LSOs), such as HTML5, to, among other things, optimize screen presentation, video and other preference information.`}</p>
    <h3>{`7. GOOGLE ANALYTICS`}</h3>
    <p>{`We use Google Analytics which is a web analytics tool that helps Us understand how users engage with Our Websites. Like many services, Google Analytics uses first-party cookies to track user interactions as in Our case, where they are used to collect information about how users use Our Website. This information is used to compile reports and to help Us improve Our Websites. The reports disclose website trends without identifying individual visitors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      