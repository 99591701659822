import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`13. CHILDREN`}</h2>
    <p>{`Our Services are not directed to persons under 18 or anyone not legally an adult in their legal jurisdiction. HostJane does not knowingly collect personal
information from children under 18. If You are a parent or guardian of such a child and become
aware that Your child has provided personal information to Us, please contact Us at the bottom of this
page and We will take reasonable steps immediately to remove any such information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      