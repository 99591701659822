import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`15. Entire Agreement`}</h2>
    <p>{`This Agreement, including documents incorporated herein by reference, supersedes all
prior discussions, negotiations and agreements between the parties with respect to the
subject matter hereof, and this Agreement constitutes the sole and entire agreement
between the parties with respect to the matters covered hereby.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      