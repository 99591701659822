import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`9. SECURITY DISCLAIMER`}</h2>
    <p>{`The transmission of information via the internet, email or text message is not completely secure. `}</p>
    <p>{`Although We will do Our best to protect Your personal information, no provider can 100% guarantee the security of any information transmitted through any Service or over email; any transmission is at Your own risk. `}</p>
    <p>{`Once HostJane has received Your information, We will take appropriate technical and organizational measures to safeguard Your personal information against loss, theft and unauthorized use, access or modification, for example, through the use of SSL encryption such as the Transport Layer Security (TLS) protocol.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      