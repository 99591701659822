import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`6. ACCOUNT TERMINATION AND ERASURE`}</h2>
    <p>{`Please note that if You request deletion of Your personal information, or if Your account is suspended, terminated, or voluntarily closed, the following information applies.`}</p>
    <h3>{`6.1 HOW TO CLOSE Your ACCOUNT`}</h3>
    <p>{`You can delete Your account and its data by going to Account Settings and selecting `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/marketplace/settings/close"
      }}>{`Close Account`}</a>{`.`}</p>
    <p>{`This will destroy Your account which cannot be recovered at a later date.`}</p>
    <p>{`Your account will be scheduled for deletion for 30 days from the date You submit Account Closure request.`}</p>
    <ul>
      <li parentName="ul">{`You'll lose all the data and content in that account, like emails, files, listings and orders.`}</li>
      <li parentName="ul">{`You will still receive any money owed to You as per Marketplace Rules and waiting period.`}</li>
      <li parentName="ul">{`If You have any outstanding orders, sales or funds processing, You won't be able to delete Your account until those are completed.`}</li>
      <li parentName="ul">{`You can login and restore account until 30 days from the date You submit Account Closure request.`}</li>
    </ul>
    <h3>{`6.1.2 Police and Payment Provider Investigations`}</h3>
    <p>{`In the event of a request from Authorities or Payment Providers during the 30-day deletion period, we may pass information for legal process investigation including combatting money laundering, fraud detection and prevention, and enhancing safety. `}</p>
    <p>{`After the 30-day waiting period, with the exception of HostJane accounts suspended for fraud or safety reasons to prevent a banned User from re-opening a new HostJane Account in the future, to protect Our community, we will generally retain no record or information related to You of any kind.`}</p>
    <h3>{`6.1.3 Legal, Tax, Reporting, and Auditing Obligations`}</h3>
    <p>{`Any non-personal information related to You to the extent necessary to comply with Our legal, tax, reporting, and auditing obligations, is collected during or prior to the 30-day deletion period, for example, the dollar amount of Your Sales necessary to use in our own tax filings.`}</p>
    <h3>{`6.2 Data access`}</h3>
    <p>{`You can always review information within Your HostJane account dashboard, orders, messaging including files, and settings areas. `}</p>
    <p>{`Obtaining a copy the account data HostJane holds about You`}</p>
    <p>{`For active accounts, or accounts within the 30-day deletion period, We do not hold any information on You outside of what You can view within the account dashboard, orders, messaging including files, and settings areas.`}</p>
    <h3>{`6.3 Data portability`}</h3>
    <p>{`In certain jurisdictions, the applicable law may allow You to receive a copy of the personal information You have provided to HostJane. `}</p>
    <p>{`You can receive a complete copy of any and all held information related to You, in a structured, commonly used, and machine-readable format, by `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/marketplace/login"
      }}>{`logging into Your account`}</a>{` and saving or downloading the information within the viewable areas of Your account dashboard, orders, messaging including files, and settings areas. `}</p>
    <p>{`We do not hold any information related to You outside of these viewable areas inside Your logged-in account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      