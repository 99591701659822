import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`7. Refunds; Cancellations; User-Initiated Termination`}</h3>
    <p><strong parentName="p">{`7.1 Non-refundable Products and Services:`}</strong>{` Due to the nature of our Server business: Unless separately negotiated by You and Us, and confirmed by separate written agreement, the administrative, initial, recurring Fees for the Servers selected by You are non-refundable.  All set-up fees and special programming fees are non-refundable. `}</p>
    <p><strong parentName="p">{`7.2 Cancellation Process:`}</strong>{` You may destroy Your Server at any time through the billing portal at: `}<a parentName="p" {...{
        "href": "https://cloud.hostjane.com/hosting/login"
      }}>{`https://cloud.hostjane.com/hosting/login`}</a>{`. We encourage users to use the two-factor authentication security available in the Hosting Console and cannot be held responsible for unforeseen data breaches.`}</p>
    <p><strong parentName="p">{`7.3 Termination:`}</strong>{` To terminate Your account, please contact HostJane at the bottom of this page to request termination of Your account. User will be required to provide Your HostJane PIN that You set-up during Your initial order (found in the Hosting Console, in addition to other ID and account verifications to enable Us to verify the cancellation is bone fide. Cancellations must be in writing and are effective immediately.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      