import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`4. No email marketing except system and alert emails`}</h2>
    <ul>
      <li parentName="ul">{`HostJane does not engage in email marketing. If You have received a marketing or promotional communication purporting to be from HostJane, please disregard or delete it.`}</li>
      <li parentName="ul">{`If You have subscribed in a HostJane marketplace category to an alert for new services, You can change Your alert preferences at any time by either clicking the "unsubscribe" link at the bottom of the alert email.`}</li>
      <li parentName="ul">{`You are not able to unsubscribe from non‑promotional communications that we need to be able to send You. If You wish to stop receiving non‑promotional communications You will need to terminate Your HostJane account by following the steps below in `}<strong parentName="li">{`6.1`}</strong>{` for how to delete Your account.`}</li>
    </ul>
    <h3>{`4.1.1 Limited data sharing with third parties`}</h3>
    <p>{`We do not collect any personal financial, payment source, or banking information from You.`}</p>
    <p>{`The minimal non-personal information we collect about You to provide the Service, will be used for the following purposes:`}</p>
    <ul>
      <li parentName="ul">{`To provide the requested Services to You;`}</li>
      <li parentName="ul">{`To provide You with useful content;`}</li>
      <li parentName="ul">{`To ensure the proper functioning of Our Services`}</li>
      <li parentName="ul">{`To offer and improve Our Services;`}</li>
      <li parentName="ul">{`To provide You with requested information or technical support`}</li>
      <li parentName="ul">{`To facilitate Your movement through Our websites or Your use of Our Services;`}</li>
      <li parentName="ul">{`To do a better job of advertising and marketing Our Services (subject to Your consent where required by applicable law);`}</li>
      <li parentName="ul">{`To advertise and market third party products and services (subject to Your consent where required by applicable law)`}</li>
      <li parentName="ul">{`To diagnose problems with Our servers or Our Services;`}</li>
      <li parentName="ul">{`In connection with Our security and compliance programs;`}</li>
      <li parentName="ul">{`To administer Our websites;`}</li>
      <li parentName="ul">{`To communicate with You;`}</li>
      <li parentName="ul">{`To target prospective customers with Our products or Services (subject to Your consent where required by applicable law);`}</li>
      <li parentName="ul">{`To assist Us in offering You a personalized experience or otherwise tailor Our Services to You; and`}</li>
      <li parentName="ul">{`As otherwise described in this privacy notice.`}</li>
    </ul>
    <p>{`We also use the information We receive to produce reports on trends and statistics, such as mobile search trends,
email open rates by industry, campaign best practices or the number of users that have been exposed to,
or clicked on, Our websites or evaluated or purchased Our products or services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      