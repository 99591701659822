import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`13. Price Changes`}</h3>
    <p>{`HostJane reserves the right to change prices or any other charges at any time. We will provide You with at least thirty (30) days notice before charging You with any price change on any annual or longer term plans. It is Your sole responsibility to periodically review billing information provided by HostJane through the user billing tool or through other methods of communication, including notices sent or posted by HostJane.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      