import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`10. LEGAL BASIS FOR PROCESSING YOUR INFORMATION`}</h2>
    <p>{`Depending on what information We collect from You and how We collect it, We rely on various
grounds for processing Your information under the GDPR, including the following:`}</p>
    <ul>
      <li parentName="ul">{`In order to administer Our contractual relationship, including setting up Your requested
Services, payments, renewals and processes;`}</li>
      <li parentName="ul">{`Because it is in Our legitimate interest to run a successful and efficient business and
provide You with the Services and other useful content;`}</li>
      <li parentName="ul">{`In order to fulfill any legal obligations We may have to collect this information from You; and/or`}</li>
      <li parentName="ul">{`Because You have provided Your consent for Us to do so.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      