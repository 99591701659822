import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`7. Indemnification`}</h2>
    <p>{`You agree to indemnify, defend and hold harmless HostJane, Our affiliates,
and their respective officers, directors, employees and agents (each an "Indemnified Party"
and, collectively, the "Indemnified Parties") from and against any and all
claims, damages, losses, liabilities, suits, actions, demands, proceedings
(whether legal or administrative), and expenses (including, but not limited to,
reasonable attorney's fees) threatened, asserted, or filed by a third party against
any of the Indemnified Parties arising out of or relating to:`}</p>
    <ol>
      <li parentName="ol">{`Your use of the Services, or;`}</li>
      <li parentName="ol">{`Any breach or violation by You of this Agreement; or;`}</li>
      <li parentName="ol">{`Any acts or omissions by You. The terms of this section shall survive
any termination of this Agreement.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      