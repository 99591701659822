import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`8. Transfer of Personal Data`}</h3>
    <p><strong parentName="p">{`8.1`}</strong>{` If Your personal data is transferred outside the EU to other HostJane affiliates or to third party service providers, we will take steps to ensure that Your personal data receives the same level of protection as if it remained within the EU, including by entering into data transfer agreements using the European Commission approved Standard Contractual Clauses. The country to which Your personal data is transferred, and whether each country benefits from a decision of the European Commission determining that the country provides adequate protection to personal data, is the United States of America. You have a right to obtain details of the mechanism under which Your personal data is transferred outside of the EU by contacting Us at the bottom of this page.`}</p>
    <p><strong parentName="p">{`8.2`}</strong>{` HostJane reserves the right to share any information that You provide which is not deemed personal data or is not otherwise subject to contractual restrictions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      