import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`2. How Buying Services Works`}</h2>
    <p><strong parentName="p">{`2.0.1`}</strong>{` Buyers places funds on hold with HostJane to create an order (with a unique identification number) based on a Seller's Listing page or Custom Offer received through HostJane's messaging system. Sellers do not have access to the Buyer's payment until the Buyer approves their Work.`}</p>
    <p><strong parentName="p">{`2.0.2`}</strong>{` Listings can be offerings for a Service that gets delivered by the Seller sending the Buyer created or edited files or code, or by for Service in which the Seller completes a virtual task for the Buyer like teaching in an online booking or building a website or software on a server. `}</p>
    <p><strong parentName="p">{`2.0.3`}</strong>{` Buyer pays a non-refundable Handling Fee of $1.99 to HostJane upon placing the Order, irregardless of the Order amount or subsequent progress. Buyer may not offer payments using any other method than placing an order through HostJane.com. Disqualification of `}<strong parentName="p">{`1.15`}</strong>{` Buyer Payment Protection and suspension of closure of account may result if HostJane business is conducted outside HostJane.`}</p>
    <p><strong parentName="p">{`2.0.4`}</strong>{` The Seller can accept or reject the Order. Buyer is advised to contact Seller prior to placing order. On Seller acceptance, a Board and chat opens in a private workspace between the Buyer and Seller for the professional purpose of their mutual collaboration on the Buyer's project or task.`}</p>
    <p><strong parentName="p">{`2.0.5`}</strong>{` HostJane partners with Payment Services Providers for purposes of collecting Listing-related payments from Buyers, transferring such payments from Buyers to Sellers, and holding funds in connection with HostJane Balances. All payments services in connection with the collection of funds on the Site are performed by HostJane’s Payment Services Providers.`}</p>
    <p><strong parentName="p">{`2.0.6`}</strong>{` For purposes of these Terms, the HostJane process for how a Listing is ordered by a Buyer is considered to be performed over three stages: (i) Pre-Delivery stage, (ii) Delivery stage, and (iii) Completion stage as defined in the decision tree below, known as `}<strong parentName="p">{`the HostJane Roadmap`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      