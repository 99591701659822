import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`4. Specific Prohibited Uses of Hosting Service`}</h3>
    <p>{`Topsites; Internet Relay Chat (IRC) Scripts/Bots; IRCD (irc servers); Proxy Scripts/Anonymizers; Image Hosting Scripts (similar to Photobucket or Tinypic); AutoSurf/PTC/PTS/PPC sites; IP Scanners; Bruteforce Programs/Scripts/Applications; Mail Bombers/Spam Scripts; Banner-Ad services (commercial banner ad rotation); File Dump/Mirror Scripts (similar to rapidshare); Commercial Audio Streaming (more than one or two streams); Escrow/Bank Debentures; High-Yield Interest Programs (HYIP) or Related Sites; Investment Sites (FOREX, E-Gold Exchange, Second Life/Linden Exchange, Ponzi, MLM/Pyramid Scheme); Sale or distribution of any controlled substance without prior proof of appropriate permit(s); Prime Banks Programs; Lottery/Gambling Sites; MUDs/RPGs/PBBGs; Hacker focused sites/archives/programs; Sites promoting illegal activities; Forums and/or websites that distribute or link to warez/pirated/illegal content; Bank Debentures/Bank Debenture Trading Programs; Fraudulent Sites; Mailer Pro; Push button mail scripts; Broadcast or Streaming of Live Sporting Events (UFC, NASCAR, FIFA, NFL, MLB, NBA, WWE, WWF, etc); Tell A Friend Scripts; Anonymous or Bulk SMS Gateways; Bitcoin Miners; PayDay Loan Sites (including any site related to PayDay loans, PayDay loan affiliate progams, etc) are all prohibited.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      