import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`12. Security Audits`}</h3>
    <p><strong parentName="p">{`12.1`}</strong>{` HostJane reserves the right to reset the password on a server if the password on file is not current so that we may do security audits as required by our datacenter. It is Your responsibility to ensure that there is a valid email address and current root password on file for Your server to prevent downtime from forced password resets. `}</p>
    <p><strong parentName="p">{`12.2`}</strong>{` HostJane reserves the right to audit servers as needed and to perform administrative actions at the request of our datacenter. Any free backups offered are provided as a courtesy and not guaranteed. It is Your responsibility to maintain backups. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      