import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`14. Your Rights`}</h3>
    <p><strong parentName="p">{`14.1`}</strong>{` Right to withdraw consent - where applicable, You have the right to withdraw Your consent at any time. For example, if You wish to opt-out of receiving electronic marketing communications, You can change Your settings in Your account on the Site, use the 'unsubscribe' link provided in Our emails or otherwise contact Us directly and we will stop sending You communications.`}</p>
    <p><strong parentName="p">{`14.2`}</strong>{` Right of access, rectification and erasure - You have the right to request access to and obtain a copy of any of Your personal data that we may hold, to request correction of any inaccurate data relating to You and to request the deletion of Your personal data under certain circumstances. You can see and update most of this data yourself online, or by contacting Us directly at the bottom of this page.`}</p>
    <p><strong parentName="p">{`14.3`}</strong>{` Data portability - where we are relying (as the legal basis for processing) upon Your consent, or the fact that the processing is necessary to perform a contract to which You are party or to take steps at Your request prior to entering a contract, and the personal data is processed by automatic means, You have the right to receive all such personal data which You have provided Us in a structured, commonly used and machine-readable format, and also to require Us to transmit it to another controller where this is technically feasible.`}</p>
    <p><strong parentName="p">{`14.4`}</strong>{` Right to restriction of processing - You have the right to restrict Our processing of Your personal data (that is, allow only its storage) where:`}</p>
    <ol>
      <li parentName="ol">{`You contest the accuracy of the personal data, until we have taken sufficient steps to correct or verify its accuracy;`}</li>
      <li parentName="ol">{`Where the processing is unlawful but You do not want Us to erase the personal data;`}</li>
      <li parentName="ol">{`Where we no longer need Your personal data for the purposes of the processing, but You require such personal data for the establishment, exercise or defence of legal claims; or`}</li>
      <li parentName="ol">{`Where You have objected to processing justified on legitimate interest grounds (see below), pending verification as to whether we have compelling legitimate grounds to continue processing.`}</li>
    </ol>
    <p><strong parentName="p">{`14.5`}</strong>{` Where Your personal data is subject to restriction we will only process it with Your consent or for the establishment, exercise or defence of legal claims.`}</p>
    <p><strong parentName="p">{`14.6`}</strong>{` You also have the right to lodge a complaint with the supervisory authority of Your habitual residence, place of work or place of alleged infringement, if You consider that the processing of Your personal data infringes applicable law.`}</p>
    <p><strong parentName="p">{`14.7`}</strong>{` Right to object to processing (including profiling) based on legitimate interest grounds - where we are relying upon legitimate interests to process personal data, You have the right to object to that processing. If You object, we must stop that processing unless we can demonstrate compelling legitimate grounds for the processing that override Your interests, rights and freedoms, or we need to process the personal data for the establishment, exercise or defense of legal claims. Where we rely upon legitimate interest as a basis for processing we believe that we can demonstrate such compelling legitimate grounds, but we will consider each case on an individual basis.`}</p>
    <p><strong parentName="p">{`14.8`}</strong>{` Right to object to direct marketing (including profiling) - You have the right to object to Our use of Your personal data (including profiling) for direct marketing purposes, such as when we use Your personal data to invite You to Our promotional events.`}</p>
    <p>{`Please contact HostJane at the bottom of this page if You wish to exercise any of Your rights, or if You have any inquiries or complaints regarding the processing of Your personal data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      