import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`1. Information We Collect Automatically`}</h3>
    <p>{`When You visit Our Site, or use Our Services, Our server automatically collects certain browser or device generated information, including but not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Your domain;`}</li>
      <li parentName="ul">{`Your IP address;`}</li>
      <li parentName="ul">{`Your date, time and duration of Your visit;`}</li>
      <li parentName="ul">{`Your browser type;`}</li>
      <li parentName="ul">{`Your operating system;`}</li>
      <li parentName="ul">{`Your page visits;`}</li>
      <li parentName="ul">{`Information from third parties;`}</li>
      <li parentName="ul">{`Other information about Your computer or device;`}</li>
      <li parentName="ul">{`Internet traffic;`}</li>
    </ul>
    <p>{`In some cases this information constitutes personal data, We do not use this automatically collected information to try to identify You by name, and we do not associate it with the information You provide voluntarily, as detailed below.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      