import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.7 Disputes`}</h3>
    <p><strong parentName="p">{`2.7.1`}</strong>{` HostJane requires Buyers and Sellers to resolve disputes before escalating the dispute to our customer service team. You can escalate a dispute by using the link in the Order Center or the "Contact Us" link in the Order page or by Contacting Us at the bottom of this page. You can open a dispute at any time from the date of purchase.`}</p>
    <p><strong parentName="p">{`2.7.2`}</strong>{` After a Dispute is opened it is assigned a Case Number and can be accessed through the Disputes Center located at: `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/marketplace/disputes"
      }}>{`https://www.hostjane.com/marketplace/disputes`}</a></p>
    <p><strong parentName="p">{`2.7.3`}</strong>{` Both parties are asked to engage in the Dispute and submit their side with information and evidence to the process. `}</p>
    <p><strong parentName="p">{`2.7.4`}</strong>{` Disputes will be fairly assessed by HostJane's customer service team on the evidence of (i) total interaction between the Buyer and Seller in Order and any messages, (ii) Work Previews and Final Work files sent and received through the Site, and (iii) information and evidence submitted in the Disputes process. During this time we encourage Users to engage with the Disputes process to uphold and protect the integrity of the final outcome.`}</p>
    <p><strong parentName="p">{`2.7.5`}</strong>{` Orders can be mutually cancelled by Buyers and Sellers, or cancellation can be initiated by HostJane to protect the integrity of the Site as described below under sections 2.9, 2.10 and 2.11. `}</p>
    <p><strong parentName="p">{`2.7.6`}</strong>{` Raising of a provably fraudulent dispute may result in account suspension or permanent banning of User from Site.`}</p>
    <p><strong parentName="p">{`2.7.7`}</strong>{` HostJane will notify Buyer and Seller of the initial outcome of assessment within three (3) business days of the dispute being raised. `}</p>
    <p><strong parentName="p">{`2.7.8`}</strong>{` HostJane's assessment can be appealed whereby a supervisor will reassess the evidence and confirm or reject the original assessment providing a final outcome which will be notified to Buyer and Seller within a further two (2) business days.`}</p>
    <p><strong parentName="p">{`2.7.9`}</strong>{` If an order is cancelled by outcome of HostJane's dispute resolution assessment, the full funds associated with that order less credit card processing fees and Handling Fee will be returned to the Buyer's Balance or the Payment source used to pay for the order.`}</p>
    <p><strong parentName="p">{`2.7.10`}</strong>{` Disputes lodged with payment agents: If, as a buyer via Your HostJane Marketplace account, You lodge a dispute with a Payment Service Provider without previously notifying HostJane of the dispute, this will result in a disabling of Your HostJane account until the dispute raised with the Payment Service Provider has been cancelled or resolved. We encourage Buyers to contact Us directly first to prevent account suspension.`}</p>
    <p><strong parentName="p">{`2.7.11`}</strong>{` HostJane (via applicable Payment Services Providers) reserves the right to cancel orders, temporarily or permanently disable accounts, and ban Users for any suspected fraudulent activity or violation of HostJane's General Terms of Service or Acceptable Use Policy found at: `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/use-policy/"
      }}>{`https://www.hostjane.com/legal/use-policy/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      