import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`8. WHY DO WE USE COOKIES?`}</h3>
    <p>{`We use the following types of cookies for the reasons described below.  These cookies may be set by Us or a third party service provider to perform the functions described below:`}</p>
    <ol>
      <li parentName="ol">{`Required: These cookies and tracking technologies are required to help Our websites work correctly.  For example, these cookies allow You to navigate Our Website and use essential features, including secure areas and shopping baskets.`}</li>
      <li parentName="ol">{`Analytics: These cookies and tracking technologies help Us understand how customers and visitors interact with Our Websites.  They provide Us with information about areas of Our Websites visited, time spent on Our Websites, transactions performed, and any error messages You receive.  These cookies allow Us to improve the performance of Our Websites.  They may collect Your IP address but only for the purpose of identifying general locations of visitors and identifying fraudulent or spam traffic.`}</li>
      <li parentName="ol">{`Functional: These cookies and tracking technologies allow Our Websites to remember choices You make to give You better functionality and a personalized experience.  For example, when You select a specific currency on one of Our Websites, We will remember Your currency selection when You return.`}</li>
      <li parentName="ol">{`Advertising: These cookies and tracking technologies allow Us to deliver content, including advertisements, relevant to Your specific interests.  This content may be delivered on Our Websites or on third party websites or services.  They allow Us to understand and improve the relevancy of Our advertisements.  They may track personal information, including Your IP address.`}</li>
    </ol>
    <h3>{`9. HOW CAN I CONTROL COOKIES?`}</h3>
    <p><strong parentName="p">{`9.1`}</strong>{` If You visit Our Websites from the European Union and/or the European Economic Area, only required cookies, as described above, will be used on Our Websites by default. Other cookies and tracking technologies will only be used when You consent on a case-by-case basis.`}</p>
    <p><strong parentName="p">{`9.2`}</strong>{` Where You visit Our Website from outside Europe, all cookies and similar tracking technologies described above will be used by default. If You would prefer not to receive personalized ads based on Your browser or device usage, You may generally express Your opt-out preference to no longer receive tailored advertisements.  Please note that You will continue to see advertisements, but they will no longer be tailored to Your interests.`}</p>
    <p><strong parentName="p">{`9.3`}</strong>{` Internet browsers allow You to change Your cookie settings via the “options’ or “preferences” menu in Your browser. Please note that if You set Your browser to refuse or block all cookies, certain features or functionalities of Our Websites will be limited or unavailable to You.`}</p>
    <p><strong parentName="p">{`9.4`}</strong>{` Some internet browsers include the ability to transmit “do not track” signals. Because no industry standard has yet been developed with respect to “do not track,” Our Websites do not currently process or respond to such “do not track” signals.`}</p>
    <p><strong parentName="p">{`9.5`}</strong>{` Some internet browsers may offer their own management tools for removing HTML5 and other Local Storage Objects.`}</p>
    <p><strong parentName="p">{`9.6`}</strong>{` You can opt out of Google Analytics without affecting how You visit Our Site. For more information on opting out of being tracked by Google Analytics across all websites You use, visit this Google page: `}<a parentName="p" {...{
        "href": "https://tools.google.com/dlpage/gaoptout"
      }}>{`https://tools.google.com/dlpage/gaoptout`}</a>{`.`}</p>
    <p><strong parentName="p">{`9.7`}</strong>{` To learn how to manage privacy and storage settings for Flash cookies, visit `}<a parentName="p" {...{
        "href": "http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
      }}>{`http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html`}</a>{`.`}</p>
    <p><strong parentName="p">{`9.8`}</strong>{` Further information about deleting and blocking cookies can be found at `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org"
      }}>{`http://www.allaboutcookies.org`}</a>{`.`}</p>
    <p><strong parentName="p">{`9.9`}</strong>{` Further information about Our advertisers’ use of cookies can be found at:`}</p>
    <ul>
      <li parentName="ul">{`European Interactive Digital Advertising Alliance: `}<a parentName="li" {...{
          "href": "http://www.youronlinechoices.eu/"
        }}>{`http://www.youronlinechoices.eu/`}</a></li>
      <li parentName="ul">{`Internet Advertising Bureau (US): `}<a parentName="li" {...{
          "href": "https://www.iab.com/"
        }}>{`https://www.iab.com/`}</a></li>
      <li parentName="ul">{`Internet Advertising Bureau (EU): `}<a parentName="li" {...{
          "href": "https://www.iabeurope.eu/"
        }}>{`https://www.iabeurope.eu/`}</a></li>
    </ul>
    <h3>{`10. HOW CAN I GET FURTHER INFORMATION?`}</h3>
    <p>{`If You have any questions about Our use of cookies or other tracking technologies, please contact Us at the bottom of this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      