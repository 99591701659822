import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2. Third-Party Websites`}</h3>
    <p><strong parentName="p">{`2.1`}</strong>{` The Services may contain links to other websites that are not owned or controlled by HostJane ("Third Party Sites"), as well as articles, photographs, text, graphics, pictures, designs, sound, video, information, and other content or items belonging to or originating from third parties ("Third Party Content"). `}</p>
    <p><strong parentName="p">{`2.2`}</strong>{` We are not responsible for any Third Party Sites or Third Party Content accessed through the Services. Third Party Sites and Third Party Content are not investigated, monitored or checked for accuracy, appropriateness, or completeness by Us. If You decide to access Third Party Sites or to access or use any Third Party Content, You do so at Your own risk and You should be aware that our terms and policies no longer govern. You should review the applicable third party's terms and policies, including privacy and data gathering practices of any website to which You navigate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      