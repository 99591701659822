import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`2.3`}</strong>{` You will be liable for damages (including costs and attorneys' fees) if You materially misrepresent that any alleged User Content is infringing Your intellectual property rights. Accordingly, if You are not sure whether material available online infringes Your copyright, You should contact an attorney.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      