import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.13 Reviews`}</h3>
    <p>{`We believe an honest, transparent review and feedback system is core to the safety and integrity of HostJane, and the health of the marketplace as a resource for Buyers paying for freelance services.`}</p>
    <p><strong parentName="p">{`2.13.1`}</strong>{` To complete orders, Buyer must leave public feedback when they approve the Work Preview which releases payment to Seller pending the five (5) day Waiting Period. `}</p>
    <p><strong parentName="p">{`2.13.2`}</strong>{` Feedback is public and can only be left in response to the completion of a valid, legally authorized order. Sellers can publicly respond to feedback.`}</p>
    <p><strong parentName="p">{`2.13.3`}</strong>{` Historical Buyer feedback and past Seller responses cannot be edited or removed by Buyer or Seller to protect the integrity of the feedback system.`}</p>
    <p><strong parentName="p">{`2.13.4`}</strong>{` Negative feedback is not removed by HostJane unless it contains unlawful, doxxing, copyright infringing or otherwise vexatious content or material in violation of HostJane's General Terms of Service or Acceptable Use Policy.`}</p>
    <p><strong parentName="p">{`2.13.5`}</strong>{` Buyers are encouraged to communicate their full and honest reviews of every aspect of their experience dealing with Sellers.`}</p>
    <p><strong parentName="p">{`2.13.6`}</strong>{` Unless Buyer links to Final Work, the publishing of Work Previews or Final Work cannot be attached to public feedback.`}</p>
    <p><strong parentName="p">{`2.13.7`}</strong>{` HostJane takes alleged abuse of the feedback system seriously and will investigate any and all concerns raised by Users or visitors, including allegations of false or paid reviews designed to unfairly advantage a Seller's Listing. Appropriate action is taken if any abuse is proven to have occurred.`}</p>
    <p><strong parentName="p">{`2.13.8`}</strong>{` Any delaying or withholding of the delivery of Final Work to Buyer in return for a better review is considered a serious violation of both the Buyer's trust and HostJane's policies and can result in permanent banning of the Seller.`}</p>
    <p><strong parentName="p">{`2.13.9`}</strong>{` Review manipulation by Buyers in attempt to coerce or secure future Services from Seller at a lesser cost is also strictly prohibited and can result in Buyer account closure. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      