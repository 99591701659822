import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Service Level Agreement`}</h2>
    <p>{`HostJane offers a 99.9% uptime guarantee via this Service Level Agreement ("SLA") based on network and host node availability. `}</p>
    <p>{`This SLA is provided as a supplement to HostJane's Web Hosting Terms of Service available at: `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/hosting-terms/"
      }}>{`https://www.hostjane.com/legal/hosting-terms/`}</a>{`.`}</p>
    <p>{`This uptime guarantee is applicable on a per-service-item basis and is not applied to Your entire invoice. For example, if You have three (3) servers with Us, and one (1) of those servers experiences downtime, any credit due to You under this uptime guarantee would be proportional to that one (1) server's downtime and not Your entire account with Us. `}</p>
    <p>{`This uptime guarantee does not apply to the accessibility of HostJane's web property, DNS servers, API, or control panel.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      