import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`3.3`}</strong>{` Any violation of this strict Anti-Spam Policy will be grounds for immediate termination and forfeiture of all unpaid revenue or entitlement thereto. We reserve the right to audit and investigate compliance with the Anti-Spam Policy at any time, with or without notice.`}</p>
    <p><strong parentName="p">{`3.4`}</strong>{` We are committed to stemming the flow of Spam that is prohibited by law. However, Our commitment to eliminating Spam goes much further than what the law requires. Spam, in all its forms, is vexatious, and is inconsistent with Our corporate policies. Consistent with this commitment and Our policies, we prohibit types of Spam that have not yet been contemplated by the law, including:`}</p>
    <ul>
      <li parentName="ul">{`Spim or instant messenger Spam;`}</li>
      <li parentName="ul">{`Spamming of internet newsgroups;`}</li>
      <li parentName="ul">{`Spamming on Craigslist or other classified services, except in areas designated for such use;`}</li>
      <li parentName="ul">{`Use of any personal service to Spam other members;`}</li>
      <li parentName="ul">{`Any other method of Spamming.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      