import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`3.5`}</strong>{` All affiliates, agents, employees or other promoters using any form of electronic commercial mail promotion in violation of this policy agree to indemnify and hold HostJane harmless from any and all claims, charges, counts, debts, suits or other allegations arising from violations of the Act, or other applicable laws regulating transmission of commercial email. HostJane shall provide immediate notice of any and all such claims, however the Site shall select its own attorneys to defend such claims, at the sole and exclusive expense of the affiliate, agent, employee or promoter responsible for the alleged violation.`}</p>
    <p><strong parentName="p">{`3.6`}</strong>{` Websites advertised via spam (Spamvertised) may not be hosted on Our servers. This provision includes, but is not limited to, spam sent via fax, phone, postal mail, email, instant messaging, or usenet/newsgroups. No organization or entity listed in Register of Known Spam Operations database (`}<a parentName="p" {...{
        "href": "https://www.spamhaus.org/rokso/"
      }}>{`https://www.spamhaus.org/rokso/`}</a>{`)  may be hosted on Our servers. Any User account which results in Our IP space being blacklisted will be immediately suspended and/or terminated.`}</p>
    <p><strong parentName="p">{`3.7`}</strong>{` HostJane reserves the right to require changes or disable as necessary, any website, account, database, or other component that does not comply with Our policies, or to make any such modifications in an emergency that we deem necessary at Our sole discretion.`}</p>
    <p><strong parentName="p">{`3.8`}</strong>{` HostJane reserves the right to charge the holder of the account used to send any unsolicited e-mail a cleanup fee or any charges incurred for blacklist removal at Our sole discretion.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      