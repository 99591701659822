import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`2.4`}</strong>{` Any filed notice of infringement must be sent to `}<a parentName="p" {...{
        "href": "mailto:copyright@hostjane.com"
      }}>{`copyright@hostjane.com`}</a>{` and contain the following elements:`}</p>
    <ul>
      <li parentName="ul">{`A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;`}</li>
      <li parentName="ul">{`Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted words at a single site are covered by a single notification, a representative list of such works at that site;`}</li>
      <li parentName="ul">{`Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit HostJane to locate the material;`}</li>
      <li parentName="ul">{`Information reasonably sufficient to permit HostJane to contact You, such as an address, telephone number, and, if available, an email address;`}</li>
      <li parentName="ul">{`A statement that You have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, the copyright owner's agent, or the law;`}</li>
      <li parentName="ul">{`A statement that the information in the notification is accurate, and under penalty of perjury that You are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      