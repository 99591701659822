import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3. WHAT KINDS OF COOKIES AND TRACKING TECHNOLOGIES DO WE USE?`}</h3>
    <p><strong parentName="p">{`PERSISTENT AND SESSION COOKIES`}</strong></p>
    <p>{`We use two categories of cookies: Persistent cookies and session cookies. Persistent cookies are cookies that help Us recognize You. They are stored on Your device in between browser sessions, allowing Us to remember Your preferences and actions across multiple sites and on multiple visits. Session cookies expire at the end of Your browser session, allowing Us to link Your actions during a particular browsing session.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      