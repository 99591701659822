import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`2.7`}</strong>{` In order to ensure that copyright owners do not wrongly insist on the removal of materials that actually do not infringe their copyrights, the safe harbor provisions require service providers to notify the subscribers if their materials have been removed and to provide them with an opportunity to send a written notice to the service provider stating that the material has been wrongly removed. `}{`[512(g)]`}</p>
    <p><strong parentName="p">{`2.8`}</strong>{` If a User provides a proper "counter-notice" claiming that the material does not infringe copyrights, the service provider must then promptly notify the claiming party of the individual's objection. `}{`[512(g)(2)]`}{` If the copyright owner does not bring a lawsuit in district court within 14 days, the service provider is then required to restore the material to its location on its network. `}{`[512(g)(2)(C)]`}</p>
    <p><strong parentName="p">{`2.9`}</strong>{` If it is determined that the copyright holder misrepresented its claim regarding the infringing material, the copyright holder then becomes liable for any damages that resulted from the improper removal of the material. `}{`[512(f)]`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      