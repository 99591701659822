import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.6 Revisions`}</h3>
    <p><strong parentName="p">{`2.6.1`}</strong>{` The amount of revisions to delivered Work Preview offered by Seller is determined before the purchase of any order. Seller must complete revisions offered for a Listing as stated on their Listing page at the time the order was placed. `}</p>
    <p><strong parentName="p">{`2.6.2`}</strong>{` Sellers must offer at least 1 revision to delivered Work Preview.`}</p>
    <p><strong parentName="p">{`2.6.3`}</strong>{` Buyers request revisions to the Work Preview through the Order page during the delivery stage of the order.`}</p>
    <p><strong parentName="p">{`2.6.4`}</strong>{` Abuse of the system to gain unpaid revisions or more Work from seller than was originally merited by the Seller's Service is strictly prohibited and may result in temporary or permanent suspension of Buyer’s account.`}</p>
    <p><strong parentName="p">{`2.6.5`}</strong>{` Sellers warrant to Buyers under section 2.4(5) that the Final Work will match the Work Preview the Seller used to gain Buyer's approval. Requests to revise Final Work are therefore assessed by HostJane to be disputes. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      