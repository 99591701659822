import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`4. Outage Credit`}</h3>
    <p>{`In accordance with the procedure outlined below, you must initiate a support ticket and request that a credit be applied to your account. Merely initiating a support ticket related to an outage will not result in any credit to your account. Initiating a support ticket and requesting a credit is what triggers an event for any potential credits; however, HostJane must still determine, in its sole discretion, whether or not an eligible outage has occurred. If HostJane determines that an eligible outage has occurred, then the ticket you generated will be used to generate a credit prorated for the amount of time the server is down. `}</p>
    <h3>{`5. How To Obtain Your Credit`}</h3>
    <p><strong parentName="p">{`5.1`}</strong>{` In order to receive any credit offered under this SLA, You must initiate a support ticket (by contacting Us at the bottom of this page) related to the event AND expressly request that We issue a credit. DO NOT ASSUME THAT WE ARE AWARE OF YOUR OUTAGE. Your outage may be wholly unrelated to HostJane's Services, so unless You contact Us via a support ticket, We may not be aware any problem exists. Outages related to hardware or other services or events not under HostJane's control are not eligible for any guarantee or credit offered under this SLA.`}</p>
    <p><strong parentName="p">{`5.2`}</strong>{` As stated herein, the timestamp on the support ticket will mark the beginning of an outage should HostJane determine that an eligible outage occurred. If HostJane was aware of the issue before You were, HostJane may notify You of the outage. In such instance, You must still initiate a support ticket to confirm that You are aware of the outage, and such support ticket must still also request a credit. `}</p>
    <p><strong parentName="p">{`5.3`}</strong>{` You must have a support ticket number in order to receive any credits under this SLA.`}</p>
    <p><strong parentName="p">{`5.4`}</strong>{` You agree and acknowledge that all other terms, limitations, exclusions, disclaimers and requirements contained in HostJane's General Terms and Web Hosting Terms apply to this SLA.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      