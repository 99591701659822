import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`3. Account Security`}</h3>
    <p><strong parentName="p">{`3.1`}</strong>{`	It is Your responsibility to ensure that scripts/programs installed under Your account are secure and permissions of directories are set properly, regardless of the installation method. When at all possible, set permissions on most directories to 755 or as restrictive as possible. Users are ultimately responsible for all actions taken under their account. This includes the compromise of credentials such as user name and password. `}</p>
    <p><strong parentName="p">{`3.2`}</strong>{`	You are required to use a secure password. If a weak password is used, Your account may be suspended until You agree to use a more secure password. Audits may be done to prevent weak passwords from being used. If an audit is performed, and Your password is found to be weak, we will notify You and allow time for You to change or update Your password before suspending Your account.`}</p>
    <p><strong parentName="p">{`3.3`}</strong>{`	The Services, including all related equipment, networks and network devices are provided only for authorized customer use. HostJane may, but is not obligated to, monitor our systems, including without limitation, to ensure that use is authorized, to facilitate protection against unauthorized access, and to verify security procedures, survivability, and operational security. During monitoring, information may be examined, recorded, copied and used for authorized purposes. `}</p>
    <p><strong parentName="p">{`3.4`}</strong>{`	By using the Services, You consent to monitoring for the following purposes.`}</p>
    <ol>
      <li parentName="ol">{`Any account found connecting to a third party network or system without authorization from the third party is subject to suspension. Access to networks or systems outside of Your direct control requires the express written consent of the third party. HostJane may, at our discretion, request documentation to prove that Your access to a third party network or system is authorized.`}</li>
      <li parentName="ol">{`Any account which causes Us to receive an abuse report may be terminated and/or have access to Services suspended. If You do not remove malicious content from Your account after being notified by HostJane of an issue, we reserve the right to leave access to Services disabled.`}</li>
      <li parentName="ol">{`HostJane reserves the right to migrate Your account from one data center to another in order to comply with applicable data center policies, local law or for technical or other reasons without notice.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      