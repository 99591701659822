import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`16. COMPLAINTS`}</h2>
    <p>{`If You have any questions about this privacy notice or Our data handling practices, or You wish to
make a complaint, please contact Us at the bottom of this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      