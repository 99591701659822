import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.11 HostJane Initiated Cancellations of Completed Orders`}</h3>
    <p>{`HostJane will cancel completed orders, where Seller has delivered the Final Work and Buyer has approved that Work and released funds, under the following conditions:`}</p>
    <p><strong parentName="p">{`2.11.1`}</strong>{` The Seller has failed to deliver the Final Work to the Buyer inside the twenty-four (24) hour delivery period in 2.4(4) which commences at the time Buyer completes the Order by approving the Work Preview and releasing funds with public feedback. If this failure to deliver Final Work is found to be in relation to an attempted Review manipulation by the Seller or as a violation of HostJane's General Terms or Acceptable Use Policy, the Seller risks being permanently banned.`}</p>
    <p><strong parentName="p">{`2.11.2`}</strong>{` If after an investigation by HostJane there has been found to have been a violation of HostJane's General Terms of Service or Acceptable Use Policy by either party, before or after the five (5) day Waiting Period elapses and irrespective of whether net earnings have been paid out to Seller.`}</p>
    <p><strong parentName="p">{`2.11.3`}</strong>{` In the rare event a completed order is cancelled by HostJane before the five (5) day Waiting Period has elapsed, the full funds associated with that order less Payment Service Provider processing fees and Handling Fee will be returned to the Buyer's Balance or the Payment source used to pay for the order.`}</p>
    <p><strong parentName="p">{`2.11.4`}</strong>{` If cancellation of a completed order occurs after the seller has been paid out the net funds from the order, HostJane will bear costs of refund to Buyer and a corresponding deduction of net fees related to refund will made to the Seller's Balance. If the Seller has not retained enough funds in their Balance to cover the deduction, the Seller will be given three (3) business days to return the payout associated with the cancelled order, before further action is taken.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      