import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.5 Applicable Taxes:`}</h3>
    <p><strong parentName="p">{`2.5.1 U.S. Sales Tax; European VAT; Australian GST.`}</strong>{` Prices and fees for services provided to You (whether by HostJane or Sellers) in connection with these terms generally exclude US Sales Tax, European VAT or Australian GST, unless stated otherwise on our Site. If a supply is made to You under or in connection with these terms on which US Sales Tax, EU VAT, or AU GST is imposed, any applicable fee that is charged for that supply is increased by, and You must also pay, an amount equal to the GST, VAT or US Sales Tax payable (by HostJane or the Seller) on that supply.`}</p>
    <p><strong parentName="p">{`2.5.2 Other Taxes.`}</strong>{` Prices, fees and charges are otherwise inclusive of other applicable taxes unless stated otherwise on the Site. You are responsible for paying all such taxes and imposts associated with Your use of HostJane wherever levied. Your responsibility includes withholding tax if it applies, unless we already process that withholding tax. We may collect geographical location information to determine Your location, which may be used for tax residency purposes.`}</p>
    <p><strong parentName="p">{`2.5.3`}</strong>{` Sellers are responsible for paying any direct or indirect taxes, including any European VAT or Australian GST or otherwise, which may apply to them depending on residency or location. Sellers represent and warrant that they comply, and will comply at all times, with their obligations under income tax provisions in their jurisdiction. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      