import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`6. Billing; Payments`}</h3>
    <p><strong parentName="p">{`6.1 Prepayment:`}</strong>{` It is Your responsibility to ensure that Your payment information is up to date, and that all invoices are paid on time. You agree to pay for the Services in advance of the time period during which such Services are provided. Subject to applicable laws, rules, and regulations, payments received will be first applied to the oldest outstanding invoice in Your billing account.`}</p>
    <p><strong parentName="p">{`6.2 Autorenewal:`}</strong>{` Unless otherwise provided, You agree that until and unless You notify HostJane of Your desire to cancel the Services, You will be billed on an automatically recurring basis to prevent any disruption to Your Services, using Your credit card or other billing information on file with Us.`}</p>
    <p><strong parentName="p">{`6.3 Applicable Taxes:`}</strong>{` Listed fees for the Services do not include any applicable sales, use, revenue, excise or other taxes imposed by any taxing authority. Any applicable taxes will be added to HostJane's invoice as a separate charge to be paid by You. All fees are non-refundable when paid unless otherwise stated.`}</p>
    <p><strong parentName="p">{`6.4 No Service without Payment:`}</strong>{` All invoices must be paid in full before any Services are rendered. If a renewal invoice is not paid before the start of the next billing cycle, continuation of the Service is suspended or terminated until payment resumes. Where Services have been suspended, Servers will be destroyed and all stored data including backups permanently deleted without retrieval.`}</p>
    <p><strong parentName="p">{`6.5 Zero-Tolerance on Fraud:`}</strong>{` It is a violation of this Agreement for You to misuse or fraudulently use credit cards, charge cards, electronic funds transfers, electronic checks, or any other payment method. HostJane may report any such misuse or fraudulent use, as determined in HostJane's sole discretion, to governmental and law enforcement authorities, credit reporting services, financial institutions and/or credit card companies.`}</p>
    <p><strong parentName="p">{`6.6 Invoice Disputes:`}</strong>{` You have ninety (90) days to dispute any charge or payment processed by HostJane. If You have any questions concerning a charge on Your account, please contact Us at the bottom of this page.`}</p>
    <p><strong parentName="p">{`6.7 Payment Card Industry Security Standard Disclaimer:`}</strong>{` HostJane complies with the Payment Card Industry Security Standard ("PCI Standard") in connection with the collection and processing of our customer's data and billing information. However, You are solely responsible for the security of the data and billing information on Your User Website. HostJane does not monitor User Websites for PCI compliance and we are not able to verify whether a User Website complies with the PCI Standard.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      