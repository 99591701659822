import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Privacy Policy`}</h2>
    <h2>{`1. ABOUT US`}</h2>
    <p><strong parentName="p">{`1.1`}</strong>{` HostJane, Inc. (“We,” “Us”, "Our", or “HostJane”) is an infrastructure provider of marketplace
and hosting services, helping small businesses and individuals to do better work with
third-party freelancers, establish and build their web presence, get found in online search,
and attract and retain more customers than their competition.`}</p>
    <p><strong parentName="p">{`1.2`}</strong>{` We care about protecting the personal information of Our customers and visitors who use Our
websites, products or services (collectively, Our “Users”). This privacy notice provides details
about how Your personal information is collected, shared and used by Us. `}</p>
    <p><strong parentName="p">{`1.3`}</strong>{` To learn more about HostJane, Inc, visit: `}<a parentName="p" {...{
        "href": "https://www.hostjane.com"
      }}>{`https://www.hostjane.com`}</a></p>
    <p><strong parentName="p">{`1.4`}</strong>{` For the purposes of the European Union’s General Data Protection Regulation 2016/679, or GDPR,
HostJane, Inc, is the data controller when You use a product or Service authored by Us. This does
not apply to third-party services provided by Our Users.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      