import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Standing Against Jew-Hatred Online`}</h2>
    <h3>{`1. Preventing Genocide`}</h3>
    <p><strong parentName="p">{`1.1`}</strong>{` HostJane aims to be a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Safe_space"
      }}>{`safe-space`}</a>{` for freelancers of all racial backgrounds including Jewish people. Our company is committed to acting ethically and with integrity to ensure
that user Services offered on our platform avoid complicity in human rights violations.`}</p>
    <p>{`On July 6, 1942, a young Jewish girl named `}<a parentName="p" {...{
        "href": "https://www.annefrank.org/en/"
      }}>{`Anne Frank`}</a>{` had to go into hiding in Amsterdam to escape extreme anti-Jewish racist hatred that had been implemented into law to marginalize, exclude and discriminate against Jewish people. `}</p>
    <p>{`Jewish children were stopped from going to school, socializing with non-Jewish children, using public transport and all public amenities including walking on the pavement and sitting on park benches, eating out in restaurants, and forced to register with the state and humiliatingly wear Yellow stars to mark them out for their race. They were brutally stopped from reaching their full intellectual potential.`}</p>
    <p>{`Tragically in Anne's case, that carefully-devised process of antisemitic violence took her life. She was murdered by racists in a concentration camp when she was 15 years old. HostJane, as a provider of internet services, sees that online antisemitism however "casual" or "low-level" has real world consequences. `}</p>
    <p>{`HostJane believes that only by taking conscious, pro-active steps to push back against rising Jew-hatred in the community will we stop history from repeating itself. `}</p>
    <p>{`Learn more about Anne Frank's secret annex where Anne and her family hid from extreme racism: `}<a parentName="p" {...{
        "href": "https://www.annefrank.org/en/"
      }}>{`https://www.annefrank.org/en/`}</a></p>
    <p><strong parentName="p">{`1.2`}</strong>{` Since May 26, 2016, the United States agreed to adopt a non-legally binding "working definition" on Antisemitism,
formulated by the the International Holocaust Remembrance Alliance (IHRA) in Budapest.`}</p>
    <p><strong parentName="p">{`1.3`}</strong>{` HostJane believes technology companies that host user-generated content have a special responsibility as corporate citizens to fight anti-Jewish racist abuse online.
In our assessment, we believe this starts by integrating the letter and the spirit of both the IHRA working definition of Antisemitism and the IHRA working definition of Holocaust Denial and Distortion into existing anti-discrimination and anti-cyberbullying efforts to protect Jewish users.`}</p>
    <p><strong parentName="p">{`1.4`}</strong>{` Our staff are trained to review purchased / resold servers, marketplace listings, messages, postings, forum and all other user-generated content for violations of the IHRA working definition and suspend or remove material that we deem to be a violation of the IHRA definitions.`}</p>
    <p><strong parentName="p">{`1.5`}</strong>{` Learn more about how the IHRA promotes Holocaust education: `}<a parentName="p" {...{
        "href": "https://www.holocaustremembrance.com/"
      }}>{`https://www.holocaustremembrance.com/`}</a></p>
    <p><strong parentName="p">{`1.6`}</strong>{` Learn more about Auschwitz-Birkenau and "the final solution" mass genocide, that is the end result when anti-Jewish racist hatred remains unchallenged in lesser stages: `}<a parentName="p" {...{
        "href": "http://auschwitz.org/en/"
      }}>{`http://auschwitz.org/en/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      