import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`11. Reseller Terms and Client Responsibility`}</h3>
    <p><strong parentName="p">{`11.1`}</strong>{` Resellers shall ensure that each of their clients complies with this Agreement.`}</p>
    <p><strong parentName="p">{`11.2`}</strong>{` Resellers are responsible for supporting their clients. HostJane does not provide support to clients of HostJane's resellers. If a reseller's client contacts HostJane, HostJane reserves the right to place a reseller client account on hold until the reseller can assume responsibility for the reseller's client. All support requests must be made by the reseller on its client's behalf for security purposes.`}</p>
    <p><strong parentName="p">{`11.3`}</strong>{` Resellers are also responsible for all content stored or transmitted under their reseller account and the actions of their clients. HostJane will hold any reseller responsible for any of their client's actions that violate the law or this Agreement.`}</p>
    <p><strong parentName="p">{`11.4`}</strong>{` HostJane is not responsible for the acts or omissions of our resellers. The reseller hereby agrees to indemnify HostJane from and against any and all claims made by any User arising from the reseller's acts or omissions.`}</p>
    <p><strong parentName="p">{`11.5`}</strong>{` HostJane reserves the right to revise our Reseller Program at any time. Changes shall take effect when posted online or on any subsequent date as may be set forth by HostJane.`}</p>
    <p><strong parentName="p">{`11.6`}</strong>{` Resellers in the HostJane Reseller Program assume all responsibility for billing and technical support for each of the Users signed up by the reseller.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      