import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`General Data Protection Regulation (GDPR) Privacy Notice`}</h2>
    <p>{`HostJane, Inc. (“HostJane”, “We”, “Us”, “Our”) believes it is important that You (“User”) understand how HostJane collects, stores, shares and uses information from and about Our Site visitors, subscribers, customers and vendors. This GDPR Privacy Notice (Notice), Our cookie policy and other legal notices posted on this website `}<a parentName="p" {...{
        "href": "http://www.HostJane.com"
      }}>{`www.HostJane.com`}</a>{` (“Site”), describes Our collection, use and disclosure of personal and non-personal data under the following conditions:`}</p>
    <ol>
      <li parentName="ol">{`Data collected through the Site;`}</li>
      <li parentName="ol">{`Data collected when You subscribe to, or otherwise use Our online Services;`}</li>
      <li parentName="ol">{`Data collected in the course of Our business activities conducted elsewhere, whenever We act as the controller of that data and when its processing is governed by the EU General Data Protection Regulation (GDPR). `}</li>
    </ol>
    <p>{`This Notice does not apply to the information collected, stored, shared, or distributed by third-party sites. `}</p>
    <p>{`This Notice may be updated from time to time.`}</p>
    <p>{`For the purposes of applicable data protection law, HostJane is the controller of any personal data collected from You on the Site, through the Services, or otherwise for the purpose of conducting or developing Our business with customers and vendors. For the purposes of this Notice, personal data means any information relating to an identified or identifiable person.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      