import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Working Definition of Holocaust Denial and Distortion`}</h2>
    <h3>{`3. Rooting Out Extremism`}</h3>
    <p><strong parentName="p">{`3.1`}</strong>{` Holocaust denial is discourse and propaganda that deny the historical reality and the extent of the extermination of the Jews by the Nazis and their accomplices during World War II, known as the Holocaust or the Shoah. Holocaust denial refers specifically to any attempt to claim that the Holocaust/Shoah did not take place.`}</p>
    <p><strong parentName="p">{`3.2`}</strong>{` Holocaust denial may include publicly denying or calling into doubt the use of principal mechanisms of destruction (such as gas chambers, mass shooting, starvation and torture) or the intentionality of the genocide of the Jewish people.`}</p>
    <p><strong parentName="p">{`3.3`}</strong>{` Holocaust denial in its various forms is an expression of antisemitism. The attempt to deny the genocide of the Jews is an effort to exonerate National Socialism and antisemitism from guilt or responsibility in the genocide of the Jewish people. Forms of Holocaust denial also include blaming the Jews for either exaggerating or creating the Shoah for political or financial gain as if the Shoah itself was the result of a conspiracy plotted by the Jews. In this, the goal is to make the Jews culpable and antisemitism once again legitimate.`}</p>
    <p><strong parentName="p">{`3.4`}</strong>{` The goals of Holocaust denial often are the rehabilitation of an explicit antisemitism and the promotion of political ideologies and conditions suitable for the advent of the very type of event it denies.`}</p>
    <p><strong parentName="p">{`3.5`}</strong>{` Distortion of the Holocaust refers, inter alia, to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Intentional efforts to excuse or minimize the impact of the Holocaust or its principal elements, including collaborators and allies of Nazi Germany;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Gross minimization of the number of the victims of the Holocaust in contradiction to reliable sources;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Attempts to blame the Jews for causing their own genocide;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Statements that cast the Holocaust as a positive historical event. Those statements are not Holocaust denial but are closely connected to it as a radical form of antisemitism.  They may suggest that the Holocaust did not go far enough in accomplishing its goal of “the Final Solution of the Jewish Question”;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Attempts to blur the responsibility for the establishment of concentration and death camps devised and operated by Nazi Germany by putting blame on other nations or ethnic groups.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`3.6`}</strong>{` HostJane will endeavor to keep this page updated in line with the IHRA definition of Holocaust Denial and Distortion therein: `}<a parentName="p" {...{
        "href": "https://holocaustremembrance.com/resources/working-definitions-charters/working-definition-holocaust-denial-and-distortion"
      }}>{`https://holocaustremembrance.com/resources/working-definitions-charters/working-definition-holocaust-denial-and-distortion`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      