import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`8. Foreign Currencies`}</h3>
    <p>{`All Services are provided in US dollars. Exchange rate fluctuations for international payments are constant and unavoidable. All refunds are processed in U.S. dollars and will reflect the exchange rate in effect on the date of the refund. All refunds are subject to this fluctuation and HostJane is not responsible for any change in exchange rates between the time of payment and the time of refund.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      