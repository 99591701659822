import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`13. External Links`}</h3>
    <p>{`The Site may contain links to third party sites. Since HostJane does not control nor is responsible for the privacy practices of those Sites, we encourage You to review the privacy policies of these third party sites. This Notice applies solely to personal data collected by Our Sites or in the course of Our business activities.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      