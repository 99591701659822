import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`5. Compatibility; Backups`}</h3>
    <p><strong parentName="p">{`5.1`}</strong>{` You agree to cooperate fully with HostJane in connection with HostJane's provision of the Services. It is solely Your responsibility to provide any equipment or software that may be necessary for Your use of the Services. To the extent that the performance of any of our obligations under this Agreement may depend upon Your performance of Your obligations, HostJane is not responsible for any delays due to Your failure to timely perform Your obligations.`}</p>
    <p><strong parentName="p">{`5.2`}</strong>{` You are solely responsible for ensuring that all User Content and User Websites are compatible with the hardware and software used by HostJane to provide the Services, which may be changed by HostJane from time to time in our sole discretion.`}</p>
    <p><strong parentName="p">{`5.3`}</strong>{` You are solely responsible for backing-up all User Content, including but not limited to, any User Websites. Backups are provided free as a courtesy but HostJane does not legally warrant that we back-up any User Content, and You agree to accept the risk of loss of any and all User Content.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      