import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2. Infringement of Intellectual Property Rights.`}</h3>
    <p><strong parentName="p">{`2.1`}</strong>{` Use of the Services to infringe upon another party's intellectual property rights is prohibited. This includes, but is not limited to, the unauthorized copying or distribution of movies, music, books, photographs, software/warez, or any other copyrighted work. Selling or offering to sell any counterfeit merchandise will result in the immediate termination of Your account. Any account found to be infringing on the intellectual property rights of others will be expeditiously removed or access to the material will be disabled. Any account found to be in repeated violation of this prohibition will be suspended or terminated.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      