import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.1 Holding; Transfer of Funds from Buyer to Seller`}</h3>
    <p><strong parentName="p">{`2.1.1`}</strong>{` If You have a HostJane account You can buy any Listing offered by any Seller with any available Payment Service Provider, including using Your Balance as a method. This ordering area can found by using the "Continue" function on a Seller’s Listing page.`}</p>
    <p><strong parentName="p">{`2.1.2`}</strong>{` `}<em parentName="p">{`Purchase Protection:`}</em>{` Buyer can only purchase Services offered on HostJane by using one of the available on-Site payment methods, which guarantees You Buyer Purchase Protection to safeguard Your funds. If Seller fails to deliver Work Preview within time frame on Listing page or delivers Work Preview of unacceptable quality, Buyer is entitled to refund less non-refundable Handling Fee.`}</p>
    <p><strong parentName="p">{`2.1.3`}</strong>{` HostJane serves as Seller's limited authorized payment collection agent solely for the purpose of accepting payments (via its Payment Services Provider, if applicable) from Buyer, and remitting those payments to Seller. `}</p>
    <p><strong parentName="p">{`2.1.4`}</strong>{` Buyer's payment obligation to Seller will be satisfied upon receipt of payment by HostJane (or its Payment Services Provider, as applicable), and HostJane (via its Payment Service Provider, as applicable) is responsible for remitting the funds to the Seller in the manner described in these Terms of Service. In the event that HostJane (via Payment Services Provider) does not remit any such amounts to Seller, the Seller will have recourse only against HostJane and not the Buyer directly.`}</p>
    <p><strong parentName="p">{`2.1.5`}</strong>{` You may not offer to pay any Seller on HostJane by another third-party method outside of the Payment Service Providers available on the Site's checkout area. `}</p>
    <p><strong parentName="p">{`2.1.6`}</strong>{` If You are asked by a Seller to make payment by an outside method, this is a serious violation by the Seller of our policies and procedures and will forfeit all unreleased earnings in Seller's account. Please report any suspected violations immediately to the HostJane customer service team at the bottom of this page.`}</p>
    <p><strong parentName="p">{`2.1.7`}</strong>{` By using the Site, You agree that HostJane can suspend Your account without notice if it is found to be buying or selling Services through HostJane by means of a third-party or off-Site payment method in violation of these Marketplace Rules.`}</p>
    <p><strong parentName="p">{`2.1.8`}</strong>{` When using Buy Now (or "Order Now" or "Continue") from the Listing or Custom Offer in Messaging, You will be redirected to a Payment Service Provider who may charge an additional processing fee to make payments to Your Seller through HostJane. `}</p>
    <p><strong parentName="p">{`2.1.9`}</strong>{` If You choose to buy using any existing credit Balance, the total cost of Your order will be deducted from Your Balance. `}</p>
    <p><strong parentName="p">{`2.1.10`}</strong>{` Any applicable Service fees (including Payment Service Provider processing fees) are added at the time of purchase where a Buyer can review and accept the total amount requested to pay. These fees cover HostJane's administrative costs.`}</p>
    <p><strong parentName="p">{`2.1.11`}</strong>{` To protect against fraud, unauthorized transactions (such as money laundering), claims or other liabilities, Your payment information is not exposed to HostJane at any time, and is subject to the privacy policy applicable to the Payment Service Provider. Please see our Privacy Policy for more information at: `}<a parentName="p" {...{
        "href": "https://www.hostjane.com/legal/privacy/"
      }}>{`https://www.hostjane.com/legal/privacy/`}</a></p>
    <p><strong parentName="p">{`2.1.12`}</strong>{` Local Currency conversion costs: If You are paying for a HostJane Listing from a payment source that is not in US dollars, You are responsible for all costs of currency conversion relating to the transaction on HostJane Marketplace. This currency conversion will include any additional fees and exchange rate fluctuations charged by Your bank or financial institution which You acknowledge and accept that HostJane does not have any control over. This policy includes funds returned to Buyer from Site through cancellation or refund of an order.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      