import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you delete you or we delete your account, it is unrecoverable, but there is a 30-day period before all records are erased. After the 30 days expires, in most cases, we will no longer hold any information on our systems related to You.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      