import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.8 Mutual Cancellations`}</h3>
    <p>{`If an order is cancelled by mutual agreement, the full funds associated with that order less Payment Service Provider processing fees and Handling Fee will be returned to the Buyer's Balance or the Payment source used to pay for the order.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      