import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.9 HostJane Initiated Cancellations in Pre-Delivery Stage`}</h3>
    <p>{`HostJane will cancel orders in the pre-delivery stage, which means before the Seller has delivered the Work Preview, under the following conditions:`}</p>
    <p><strong parentName="p">{`2.9.1`}</strong>{` There has been a violation of HostJane's General Terms of Service or Acceptable Use Policy by either party`}</p>
    <p><strong parentName="p">{`2.9.2`}</strong>{` The Seller has failed to deliver Work Preview to Buyer within the agreed time frame for delivery while failing to contact Buyer with a remedy or time extension request within twenty four (24) hours of the failure`}</p>
    <p><strong parentName="p">{`2.9.3`}</strong>{` If a non-delivered order is cancelled by HostJane, the full funds associated with that order less Payment Service Provider processing fees and Handling Fee will be returned to the Buyer’s Balance or the Payment source used to pay for the order.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      