import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`2. INFORMATION COVERED BY THIS PRIVACY NOTICE`}</h2>
    <p><strong parentName="p">{`2.1`}</strong>{` This privacy policy covers personal information, including any information We collect, use and
share from You, as described further below. This privacy notice applies to all Our websites,
Our products and Services, and Our mobile applications (collectively, the “Services”).`}</p>
    <p><strong parentName="p">{`2.2`}</strong>{` This privacy notice does not cover how Our Users may use or share data that they collect
using Our Services. When You purchase a Service, Your personal information will be collected, used,
and shared consistent with the provisions of this privacy policy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      