import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`3. INFORMATION WE COLLECT FROM YOU`}</h2>
    <p>{`In the course of Your use of the Services, We obtain the following information about You as described below. We collect this data for the purposes described under “How We Use Your Information”.`}</p>
    <h3>{`3.1 NO HOLDING OR STORAGE OF PAYMENT SOURCES`}</h3>
    <h3>{`3.1.1 INBOUND PAYMENTS`}</h3>
    <ul>
      <li parentName="ul">{`HostJane does NOT collect or hold any payment source from You directly, neither do we store any payment method nor Your credit card information. `}</li>
      <li parentName="ul">{`All payments are securely handled by `}<a parentName="li" {...{
          "href": "https://pay.amazon.com/"
        }}>{`Amazon Pay`}</a>{` or `}<a parentName="li" {...{
          "href": "https://www.paypal.com/"
        }}>{`PayPal`}</a>{` in our marketplace business, and separately, `}<a parentName="li" {...{
          "href": "https://stripe.com/"
        }}>{`Stripe`}</a>{` in our hosting business. Please consult with those third-parties for how they handle Your data.`}</li>
    </ul>
    <h3>{`3.1.2 DOMESTIC AND CROSS BORDER PAYOUTS`}</h3>
    <ul>
      <li parentName="ul">{`HostJane does NOT store or hold Your banking information. Instead, we offer a range of third-party payout providers through which Users can receive payouts or funds in U.S. dollars acquired through the Site.`}</li>
      <li parentName="ul">{`Login to Your HostJane account and navigate to `}<a parentName="li" {...{
          "href": "https://www.hostjane.com/marketplace/settings/payouts"
        }}>{`Settings > Payouts`}</a>{` for an up-to-date list of current Payout Providers available. `}</li>
      <li parentName="ul">{`Please note Payout Providers may vary depending on where You are geographically accessing HostJane from.`}</li>
    </ul>
    <h3>{`3.2 SENSITIVE IDENTITY INFORMATION`}</h3>
    <p>{`Where appropriate, we may ask You for an image of Your government-issued ID (in accordance with applicable laws) or other verification information, and/or a selfie when we verify Your ID.`}</p>
    <p>{`Depending on where You live, we accept the following types of IDs during verification:`}</p>
    <ul>
      <li parentName="ul">{`National identity card`}</li>
      <li parentName="ul">{`Driver license`}</li>
      <li parentName="ul">{`Passport`}</li>
    </ul>
    <h3>{`3.2.1 Sensitive data processing`}</h3>
    <p>{`If any copy of Your ID is provided to HostJane, it will be stored on encrypted, secure AWS servers located inside the United States and used only:`}</p>
    <ul>
      <li parentName="ul">{`To obtain information from Your ID necessary to perform services reasonably expected by the User (particularly with respect to tax reporting)`}</li>
      <li parentName="ul">{`To obtain information reasonably necessary and proportionate to help ensure security and integrity including resisting malicious, deceptive, fraudulent, or illegal actions`}</li>
      <li parentName="ul">{`To obtain information necessary to verify customer information; or`}</li>
      <li parentName="ul">{`To obtain information necessary to verify or maintain the quality or safety of the service`}</li>
    </ul>
    <p>{`You may exercise Your right to opt-out or limit the use of sensitive data processing in applicable jurisdictions by sending us an email.`}</p>
    <h3>{`3.2.2 Country/region-specific guidelines for IDs`}</h3>
    <p>{`For most countries and regions, You should provide a standard, unedited photograph of Your ID. However, there are additional guidelines if You live in Germany, Hong Kong, India, Indonesia, Japan, the Netherlands, Singapore or South Korea.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The Netherlands:`}</strong>{` Hide Your `}<a parentName="li" {...{
          "href": "https://www.netherlandsworldwide.nl/bsn/where-to-find"
        }}>{`9-digit BSN (burgerservicenummer)`}</a>{` and QR code. You may also apply a watermark to Your ID, such as ‘Copy’ or ‘Kopie’; however, please ensure the watermark does not cover other information.`}</li>
      <li parentName="ul"><strong parentName="li">{`Germany:`}</strong>{` Hide the 9-digit document number (Serienummer) and the 6-digit card access number (Zugangsnummer) on Your `}<a parentName="li" {...{
          "href": "https://www.personalausweisportal.de/Webs/PA/EN/citizens/german-id-card/data-on-the-id-card/data-on-the-id-card-node.html"
        }}>{`national identity card, passport or residence card`}</a>{`. You may also apply a watermark to Your ID, such as ‘Copy’ or ‘Kopie’; however, please ensure the watermark does not cover other information.`}</li>
      <li parentName="ul"><strong parentName="li">{`Singapore`}</strong>{`: On `}<a parentName="li" {...{
          "href": "http://ica.gov.sg/documents"
        }}>{`Singapore identity documents`}</a>{` specifically, Singapore Driver licenses, hide the License Number located at the top of the card, and on the back, hide the barcode and License Number located at the bottom of the card. On Singapore Passports, back and front, hide the Passport Number and NRIC Number located in the passport details page. On Singapore National Registration Identity Cards (NRIC), back and front, hide the NRIC number located at the top of the card, any barcodes, and the NRIC number located in the lion head emblem at the bottom right of the card.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hong Kong:`}</strong>{` Hide the `}<a parentName="li" {...{
          "href": "https://www.immd.gov.hk/eng/services/hkid/smartid.html"
        }}>{`HKID`}</a>{` which is the 7- or 8- digit number at usually the bottom right hand corner if the ID, and depending on the date of issue before or after 24 Nov 2018, may be also present as a laser image or in the see-through window at the top.`}</li>
      <li parentName="ul"><strong parentName="li">{`India:`}</strong>{` Hide Your `}<a parentName="li" {...{
          "href": "https://uidai.gov.in/en/"
        }}>{`Aadhaar or Your UIDAI ID`}</a>{` card or number when verifying Your identity.`}</li>
      <li parentName="ul"><strong parentName="li">{`Indonesia:`}</strong>{` Hide the 16-digit ID number on Your `}<a parentName="li" {...{
          "href": "https://sippn.menpan.go.id/sektor-strategis/dasar/administrasi-kependudukan/kartu-tanda-penduduk-ktp-"
        }}>{`National Identity Card (Kartu Tanda Penduduk/KTP)`}</a>{` located in the front top left corner, and on Indonesian passports, hide the 8-digit passport ID in the top right corner of the Identity Information page.`}</li>
      <li parentName="ul"><strong parentName="li">{`Japan:`}</strong>{` On `}<a parentName="li" {...{
          "href": "https://www.kojinbango-card.go.jp/en/"
        }}>{`Japan Identity cards`}</a>{`, hide the 12 digits of "My Number" on the back of Your card—located under the magnetic strip.`}</li>
      <li parentName="ul"><strong parentName="li">{`South Korea:`}</strong>{` On `}<a parentName="li" {...{
          "href": "https://www.passport.go.kr/home/kor/contents.do?menuPos=56"
        }}>{`South Korea identity documents`}</a>{`, specifically  South Korea Identity cards, passports and driver licenses, hide the last 7 digits of the 13-digit Resident Registration Number (RRN) if it is present. On South Korea passports issued after January 2021, the 7-digit Resident Registration Number (RRN often marked as "Personal No.") is usually not present.`}</li>
    </ul>
    <h3>{`3.3 INFORMATION YOU VOLUNTARILY PROVIDE TO US`}</h3>
    <p>{`HostJane collects the following information from You when You provide it to Us:`}</p>
    <ul>
      <li parentName="ul">{`Information that You provide prior to or during any registration process (including marketplace or web hosting support), or in connection with a co-branded offer, such as Your name, company name, email address, Telegram or Facebook Messenger username, phone number, geographic location and industry;`}</li>
      <li parentName="ul">{`Information You provide, when You chat or email Us (for support or otherwise) or when You use Our products or Services`}</li>
    </ul>
    <h3>{`3.4 INFORMATION WE COLLECT ABOUT YOUR USAGE AND DEVICES`}</h3>
    <p>{`We collect data relating to Your online activity on Our websites, including the following:`}</p>
    <ul>
      <li parentName="ul">{`IP address;`}</li>
      <li parentName="ul">{`Browser type and version;`}</li>
      <li parentName="ul">{`Type of device You use, operating system version, and device identifier;`}</li>
      <li parentName="ul">{`Geographic location;`}</li>
      <li parentName="ul">{`Pages You view;`}</li>
      <li parentName="ul">{`How You got to Our Services and any links You click on to leave Our Services;`}</li>
      <li parentName="ul">{`When You update Your information, communicate with Us, or order new Services;`}</li>
      <li parentName="ul">{`Metadata about Your use of Our Services and emails We send to You (including clicks and opens);`}</li>
      <li parentName="ul">{`Metadata about how Your customers, contacts, and users interact with the Services We provide You;`}</li>
      <li parentName="ul">{`Your interactions with any videos We offer;`}</li>
      <li parentName="ul">{`Issues You encounter requiring Our support or assistance; and`}</li>
      <li parentName="ul">{`Any device or other method of communication You use to interact with the Services.`}</li>
    </ul>
    <p>{`We store the data We collect in a variety of places within Our infrastructure, including system log files, back end databases and analytics systems.`}</p>
    <h3>{`3.5 INFORMATION WE ACQUIRE FROM SOCIAL MEDIA`}</h3>
    <p>{`We collect information from third party social networking sites, including information that they
provide to Us if You use Your credentials at such social networking sites to log into some of Our
Services (such as Your name and email address to pre-populate Our sign-up form). The information You
allow Us to access varies by social networking site, and depends on the level of privacy settings
You have in place at the social networking site. You can control and find out more about these
privacy settings at the applicable social networking site.`}</p>
    <h3>{`3.6 INFORMATION WE AQUIRE FROM OTHER SOURCES`}</h3>
    <p>{`We also collect the following information from other sources:`}</p>
    <ul>
      <li parentName="ul">{`Information You provide to Us at seminars or to Our partners;`}</li>
      <li parentName="ul">{`Information You provide to Us in surveys;`}</li>
      <li parentName="ul">{`Information that is publicly available; and`}</li>
      <li parentName="ul">{`Information You consent to Us receiving from third parties.`}</li>
    </ul>
    <p>{`We also collect information that You may provide during Your support conversations with HostJane staff,
which We may monitor or record. We may combine any information We collect about You with other information
We have about You to help Us update, expand and analyze Our records, identify new customers, and provide
products and Services that may be of interest to You. If You provide Us personal information about others,
or if others give Us Your information, We will only use that information for the specific reason for which
it was provided to Us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      