import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`2.12 Buyer Refunds; Credit Requests`}</h3>
    <p><strong parentName="p">{`2.12.1`}</strong>{` HostJane will assess the merits of all refund or credit requests on a case-by-case basis. There is generally no obligation to provide a refund or credit in situations like the following:`}</p>
    <ul>
      <li parentName="ul">{`You have changed Your mind about a completed, approved order;`}</li>
      <li parentName="ul">{`You bought the Service by mistake;`}</li>
      <li parentName="ul">{`You do not have the sufficient expertise to use the Final Work;`}</li>
      <li parentName="ul">{`The Seller who provided the Service is no longer available on HostJane`}</li>
      <li parentName="ul">{`The Seller has stopped responding to Your messages or has blocked You, unless the Seller’s original Listing at the time You placed the order promised You future support`}</li>
    </ul>
    <p><strong parentName="p">{`2.12.2`}</strong>{` Sellers cannot initiate reversals or refunds for completed orders without the prior written approval of HostJane`}</p>
    <p><strong parentName="p">{`2.12.3`}</strong>{` Fees associated with Payment Service Provider(s) processing of Buyer payments can generally not be refunded. `}</p>
    <p><strong parentName="p">{`2.12.4`}</strong>{` HostJane cannot be held responsible for losses associated with local currency exchange fluctuations determined by Payment Service Provider(s) when original payment source was held in a currency other than US dollars.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      