import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Acceptable Use Policy`}</h2>
    <p>{`This Acceptable Use Policy ("AUP") governs Your use of the Services and is incorporated by reference into HostJane’s General Terms of Service. Unless otherwise stated, defined terms in this AUP have the meanings provided in the Marketplace Rules and General Terms. HostJane may modify this AUP at any time without notice.`}</p>
    <p>{`All Services provided by HostJane may only be used for lawful purposes. You agree to comply with all applicable laws, rules, and regulations in connection with Your use of the Services. HostJane reserves the right to refuse Service to anyone with no reason necessarily provided. Any material that in Our judgment violates this AUP in any manner may be removed from Our servers (or otherwise disabled), with or without notice.`}</p>
    <p>{`Failure to respond to an email (or other contact request) from Our abuse department within the specified time period given in the request may result in the suspension or termination of the Services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      