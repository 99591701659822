import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`12.	Retention of Your Personal Data`}</h3>
    <p>{`We apply a general rule of keeping personal data only for as long as required to fulfill the purposes for which it was collected. In general, we retain Your personal data for a period of time corresponding to a statute of limitation, for example to maintain an accurate record of Your dealings with Us. However, in some circumstances we may retain personal data for other periods of time, for instance where we are required to do so in accordance with legal, tax and accounting requirements, or if required to do so by a legal process, legal authority, or other governmental entity having authority to make the request, for so long as required.`}</p>
    <p>{`With respect to marketing, we retain Your personal data for 18 months after Your last request for Service or other contact You initiate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      