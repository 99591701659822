import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`2.2`}</strong>{` It is Our policy to respond to clear notices of alleged copyright infringement. If You believe that Your intellectual property rights are being infringed upon, You must provide a written communication that sets forth the items specified below in Section 2.4. For copyright infringement claims, HostJane follows the United States Digital Millennium Copyright Act ("DMCA") notice and takedown procedures (the text of which can be found at the U.S. Copyright Office Web Site, `}<a parentName="p" {...{
        "href": "http://www.copyright.gov"
      }}>{`http://www.copyright.gov`}</a>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      