import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`5. Money Lending; Investment services prohibited`}</h3>
    <p>{`Crowdfunding; Securities brokers; mortgage consulting or debt reduction services; credit counselling or repair; real estate opportunities; lending instruments; Money transmitters, check cashing, wire transfers, money orders; currency exchanges or dealers; bail bonds; collections agencies; law firms collecting funds for any purpose other than to pay fees owed to the firm for services provided by the firm (e.g., firms cannot use HostJane to hold client funds, collection or settlement amounts, disputed funds, etc.) are all prohibited.`}</p>
    <h3>{`6. Non-fiat currency or stored value prohibited`}</h3>
    <ul>
      <li parentName="ul">{`Sale of cryptocurrency (for example, bitcoin, ripple, ethereum, dogecoin, cardano, and so on) exchanges and wallets is prohibited.`}</li>
      <li parentName="ul">{`Sale of first-party non-fungible tokens (NFTs) minting and sales, including marketplaces and SaaS platforms is prohibited.`}</li>
      <li parentName="ul">{`Sale of preloaded payment cards, gift cards, virtual credits or other products and services in which a monetary value is stored within the item (digital or physical) is prohibited.`}</li>
      <li parentName="ul">{`Sale of prepaid phone cards, SIM cards, and phone services are prohibited.`}</li>
      <li parentName="ul">{`Sale of stored value or credits maintained, accepted and issued by anyone other than the seller is prohibited.`}</li>
    </ul>
    <h3>{`7. Using HostJane intellectual property without express consent from HostJane`}</h3>
    <p>{`Use of the HostJane name or logo including use of HostJane trade or Service marks in a manner that otherwise harms HostJane or the HostJane brand; or any action that implies an untrue endorsement by or affiliation with HostJane is prohibited.`}</p>
    <h3>{`8. Counterfeit or unauthorized goods prohibited`}</h3>
    <p>{`Unauthorized sale or resale of brand name or designer products or services; sale of goods or services that are illegally imported or exported is prohibited.`}</p>
    <h3>{`9. Gambling prohibited`}</h3>
    <p>{`Lotteries; bidding fee auctions; sports forecasting or odds making; fantasy sports leagues with cash prizes; internet gaming; contests; sweepstakes; games of chance are all prohibited.`}</p>
    <h3>{`10.	Regulated or illegal products or services prohibited`}</h3>
    <p>{`Illicit substances; Marijuana dispensaries and related businesses; sale of tobacco, e-cigarettes, and e-liquid; online pharmacies; age restricted goods or services; weapons and munitions; gunpowder and other explosives; fireworks and related goods; toxic, flammable, and radioactive materials; products and services with varying legal status on a state-by-state basis; goods or services, the sale of which is illegal under applicable law in the jurisdictions to which your business is targeted or directed, are all prohibited.`}</p>
    <h3>{`11.	Age-restricted content and services prohibited`}</h3>
    <p>{`Pornography and other obscene materials (including literature, imagery and other media); listings or sites offering any sexually-related services such as prostitution, escorts, pay-per view, adult live chat features are all prohibited.`}</p>
    <h3>{`12.	Get rich quick schemes prohibited`}</h3>
    <p>{`Investment opportunities or other services that promise high rewards are all prohibited.`}</p>
    <h3>{`13.	Mug shot publication or pay-to-remove sites prohibited`}</h3>
    <p>{`Platforms that facilitate the publication and removal of content (such as mug shots), where the primary purpose of posting such content is to cause or raise concerns of reputational harm are all prohibited.`}</p>
    <h3>{`14.	No-value-added services prohibited`}</h3>
    <p>{`Sale or resale of a service without added benefit to the buyer; resale of government offerings without authorization or added value; sites that we determine in our sole discretion to be unfair, deceptive, or predatory towards consumers are all prohibited.`}</p>
    <h3>{`15.	Products or services that are otherwise restricted by our Payment Service Provider(s)`}</h3>
    <h3>{`16.	Aggregation prohibited`}</h3>
    <p>{`Engaging in any form of licensed or unlicensed aggregation of funds owed to third parties, factoring, or other activities intended to obfuscate the origin of funds are all prohibited.`}</p>
    <h3>{`17.	Drug paraphernalia prohibited`}</h3>
    <p>{`Any equipment designed for making or using drugs, such as bongs, vaporizers, and hookahs are all prohibited.`}</p>
    <h3>{`18.	Unfair, predatory, or deceptive practices prohibited`}</h3>
    <p>{`Any content or Services that we believe poses elevated financial risk, legal liability, or violates card network or bank policies are all prohibited.`}</p>
    <h3>{`19.	Material Promoting Hate / Violence / Targeting / Discrimination prohibited`}</h3>
    <p>{`Hate Speech; Any content or Service that a. engages in, encourages, promotes or celebrates unlawful violence or physical harm to persons or property, b. Doxxing or trolling; or C. engages in, encourages, promotes or celebrates unlawful violence toward any group based on race, religion, disability, gender, sexual orientation, national origin, or any other immutable characteristic are all prohibited.`}</p>
    <h3>{`20.	Multi-level marketing prohibited`}</h3>
    <p>{`Pyramid schemes, network marketing, and referral marketing programs are all prohibited.`}</p>
    <h3>{`21.	Pseudo pharmaceuticals prohibited`}</h3>
    <p>{`Pharmaceuticals and other products that make health claims that have not been approved or verified by the applicable local and/or national regulatory body are all prohibited.`}</p>
    <h3>{`22.	Social media activity`}</h3>
    <p>{`Sale of X (formerly Twitter) followers, Facebook likes, YouTube views, and other forms of social media activity is prohibited.`}</p>
    <h3>{`23.	Substances designed to mimic illegal drugs prohibited`}</h3>
    <p>{`Sale of any legal substance that provides the same effect as an illegal drug (e.g., salvia, kratom) is prohibited.`}</p>
    <h3>{`24.	Video game or virtual world credits prohibited`}</h3>
    <p>{`Sale of in-game currency unless the merchant is the operator of the virtual world is prohibited.`}</p>
    <h3>{`25.	Use of HostJane in a manner inconsistent with its intended use or as expressly prohibited in the HostJane General Terms`}</h3>
    <p>{`Use of HostJane principally as a virtual terminal (e.g., submitting card transactions by manually inputting card information); processing where there is no bona fide good or service sold, or donation accepted; card testing; evasion of card network chargeback monitoring programs; sharing cardholder information with another merchant for payment cross-sell product or service`}</p>
    <h3>{`26.	Other prohibited content`}</h3>
    <p>{`We reserve the right to refuse service to anyone. Any material that, in our judgment, is obscene, threatening, illegal, or violates our Terms of Service or this AUP in any manner may be removed from our servers (or otherwise disabled), with or without notice.`}</p>
    <h3>{`27.	Any content, use, or words related to legal minors prohibited`}</h3>
    <p>{`In addition to the General Term that no one under the age of eighteen (18) years of age ("legal minor") can hold a HostJane account; User cannot publish any content related to legal minors whatsoever on HostJane, use HostJane servers in connection with legal minors, or use words related to or referencing legal minors in any part of Site. `}</p>
    <h3>{`28. Remote technical support services prohibited`}</h3>
    <p>{`Use of HostJane to provide services involving remote technical support or any activity needing remote access to a Buyer's computer or other device to initiate or complete a purchase or to provide the service being paid for.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      