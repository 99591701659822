import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`15. NOTIFICATION OF CHANGES`}</h2>
    <p>{`We reserve the right to modify this privacy policy at any time, so please review it frequently.
If We decide to change this privacy notice in any material way, We will notify You here, by email,
or by means of a notice on `}<a parentName="p" {...{
        "href": "http://www.HostJane.com"
      }}>{`www.HostJane.com`}</a>{` or the website of the HostJane product or Service You
use prior to the change becoming effective. In all cases, Your continued use of any Services
constitutes acceptance to any such changes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      