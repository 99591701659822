import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here are the screw ups that mean Buyer gets a refund. It has to be one of these screw ups for a refund to happen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      