import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`9. Independent Contractor`}</h2>
    <p>{`HostJane and User are independent contractors and nothing contained in this Agreement
places HostJane and User in the relationship of principal and agent, partners or joint venturers.
Neither party has, expressly or by implication, or may represent itself as having, any authority
to make contracts or enter into any agreements in the name of the other party, or to obligate or
bind the other party in any manner whatsoever.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      