import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`22. Third-Party Beneficiaries`}</h2>
    <p>{`Except as otherwise expressly provided in this Agreement, nothing in this Agreement is intended,
nor shall anything herein be construed to confer any rights in any person other than the
parties hereto and their respective successors and permitted assigns. Notwithstanding the
foregoing, User acknowledges and agrees that any supplier of a third-party product or service
that is identified as a third-party beneficiary in the service description, is an intended
third-party beneficiary of the provisions set forth in this Agreement as they relate
specifically to its products or services and shall have the right to enforce directly the
terms and conditions of this Agreement with respect to its products or services against User
as if it were a party to this Agreement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      