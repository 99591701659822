import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`6. Limitation of Liability`}</h2>
    <p>{`IN NO EVENT WILL HOSTJANE ITS DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU
OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA
ARISING FROM YOUR USE OF THE SERVICES, OR ANY USER CONTENT, USER WEBSITES
OR OTHER MATERIALS ACCESSED OR DOWNLOADED THROUGH THE SERVICES, EVEN IF
HOSTJANE IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, HOSTJANE'S LIABILITY
TO YOU, OR ANY PARTY CLAIMING THROUGH YOU, FOR ANY CAUSE WHATSOEVER,
AND REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE AMOUNT PAID,
IF ANY, BY YOU TO HOSTJANE FOR THE SERVICES IN THE THREE (3) MONTHS PRIOR
TO THE INITIAL ACTION GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT.
THE EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      